<template>
  <div class="">
    <promo-section
      class="promo--coverage"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
    />
    <plans-section />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import PlansSection from '@/components/coverage-page/PlansSection.vue';
import promoImage from '@/assets/images/covarage-promo.svg';

export default {
  name: 'Coverage',
  components: {
    PromoSection,
    PlansSection,
  },
  data() {
    return {
      promo: {
        subtitle: 'Coverage',
        title: 'Plans made easy so you can do what you do best. <span class="text-accent">Drive.</span>',
        image: promoImage,
      },
    };
  },
};
</script>
