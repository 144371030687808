<template>
  <div
    class="plans"
    id="plans"
  >
   <div class="plans__container container">
     <div class="plans__row">
        <div
          v-for="plan in plans"
          :key="plan.id"
          class="plans__col"
        >
          <plan-card class="plans__card" :plan="plan" />
        </div>
      </div>
   </div>
  </div>
</template>

<script>
import PlanCard from '@/components/coverage-page/PlanCard.vue';

export default {
  name: 'PlansSection',
  components: { PlanCard },
  data() {
    return {
      plans: [
        {
          id: 1,
          color: '#1CB0FF',
          gradientFrom: '#4560FF',
          gradientTo: '#0EC9FE',
          title: 'Automobile Liability',
          text: 'Covers damage to others (3rd parties) as required by law.',
          points: [
            'Limit up to $1M (CSL)',
            'Meet state limit requirements',
            'Safety Programs available (Dashcams and Coaching) to help you save even more!',
          ],
        },
        {
          id: 2,
          color: '#9929E9',
          gradientFrom: '#A931E5',
          gradientTo: '#4F04FE',
          title: 'Physical Damage',
          text: 'Covers damage to your own truck and equipment',
          points: [
            'Covers Truck and Trailer',
            'Options for Non-Owned Trailers',
            'Options for Trailer Interchange',
          ],
        },
        {
          id: 3,
          color: '#1EC9AB',
          gradientFrom: '#16B2B2',
          gradientTo: '#2CF29F',
          title: 'Cargo',
          text: 'Covers cargo in your posession that you are hauling',
          points: [
            'Limits up to $250,000',
            'Refrigeration Coverage Available',
            'Broad Form for wide range of covered Commodities',
          ],
        },
        {
          id: 4,
          color: '#1CB0FF',
          gradientFrom: '#4560FF',
          gradientTo: '#0EC9FE',
          title: 'Truckers General Liability',
          text: 'Covers injuries or property damage you cause as a result of business activities not directly related to operating your truck',
          points: [
            'Customers Slipping/ falling on your premises',
            'Erroneous delivery of products resulting in damage',
            'Actions of a driver while representing the insured on the premises of others, such as, loading docks, truck stops, etc.',
          ],
        },
        {
          id: 5,
          color: '#1EC9AB',
          gradientFrom: '#16B2B2',
          gradientTo: '#2CF29F',
          title: 'Non-Trucking Liability Insurance',
          text: 'Covers independent semi drivers outside the scope of their lease agreement with the motor carrier',
          points: [
            'Coverage generally applies when semi operators are driving their big rigs without trailers on their own time',
            'Does not provide coverage to semi drivers while they are under dispatch',
            'Covers independent semi drivers outside the scope of their lease agreement with the motor carrier',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.plans {
  padding: 100px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $sm) {
    padding: 25px 0;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    padding: 0 50px;

    @include breakpoint(0, $xlg) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      padding: 0;
    }

    @include breakpoint(0, $md) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  &__card {
    height: 100%;
    display: flex;
    flex-direction: column;

    :deep {

      .plan-card__body {
        flex: auto;
        display: flex;
        flex-direction: column;
      }

      .plan-card__actions {
        margin-top: auto;
      }

    }
  }

}
</style>
