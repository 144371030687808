<template>
  <div class="plan-card">
    <header class="plan-card__header" :style="bgColor">
      <h4 class="plan-card__title" v-text="plan.title" />
      <div class="plan-card__text" v-text="plan.text" />
    </header>
    <div class="plan-card__body">
      <ul class="plan-card__points list-unstyled">
        <li
          v-for="(point, index) in plan.points"
          :key="index"
          class="plan-card__point"
          :style="{ color: plan.color }"
        >
          <span
            class="plan-card__icon"
                :style="{ backgroundColor: plan.color }"
          >
            <main-icon
              :style="{ backgroundColor: plan.color }"
              name="check" />
          </span>
          {{ point }}
        </li>
      </ul>
      <div class="plan-card__actions">
        <main-button
          :style="{ backgroundColor: plan.color }"
          class="plan-card__button btn--thirty btn--lg btn--full"
        >
          GET QUOTE
        </main-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PlanCard',
  props: {
    plan: Object,
  },
  computed: {
    bgColor() {
      return {
        backgroundColor: this.plan.gradientFrom,
        background: `linear-gradient(135deg, ${this.plan.gradientFrom} 0%, ${this.plan.gradientTo} 100%)`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.plan-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.16);

  &__header {
    padding: 25px;
    background-color: $blue;
    background: linear-gradient(135deg, #4560FF 0%, #0EC9FE 100%);
  }

  &__title {
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 1.33;
    color: $light;
    font-weight: 400;

    @include breakpoint(0, $lg) {
      font-size: 21px;
    }
  }

  &__text {
    font-size: 14px;
    color: $light;

    @include breakpoint(0, $lg) {
      font-size: 11px;
    }

  }

  &__body {
    background-color: $light;
    padding: 10px;
  }

  &__points {
    padding: 0 15px;
  }

  &__point {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: $blue;

    @include breakpoint(0, $lg) {
      font-size: 11px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__icon {
    position: relative;
    top: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    flex: none;
  }

}
</style>
